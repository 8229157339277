import React, { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import definition from "@/game-marvel-rivals/definition.mjs";
import Accounts from "@/hub-settings/Accounts.jsx";
import { PageContainer } from "@/hub-settings/Common.style.jsx";
import isEmpty from "@/util/is-empty.mjs";
import isObject from "@/util/is-object.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function AccountsMarvelRivals() {
  const state = useSnapshot(readState);
  const marvel: Record<string, { name: string; avatar: string }> =
    state.settings.loggedInAccounts[definition.shortName];
  const profiles = useMemo(
    () =>
      Object.entries(marvel ?? {})
        .filter(
          ([, profile]) =>
            !(profile instanceof Error) &&
            isObject(profile) &&
            !isEmpty(profile),
        )
        .map(([profileId, profile]) => ({
          displayName: profile.name,
          key: profileId,
          avatar: profile.avatar,
        })),
    [marvel],
  );
  return (
    <PageContainer>
      <Accounts
        profiles={profiles}
        gameShort={definition.shortName}
        autoDetectText={[
          "marvel:settings.autoDetectProfile",
          "Hey! I can automatically detect your profile. Just open Marvel Rivals and log into the account you want to connect.",
        ]}
        renderOtherActionItems={undefined}
        renderOtherItems={undefined}
      />
    </PageContainer>
  );
}
